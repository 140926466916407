import React, { useState, useEffect, useRef } from "react";
import block from "bem-css-modules";
import IconArrowRight from '../../Icons/IconArrowRight';
import button from "../../../styles/components/Button.module.scss";
import dropdown from "../../../styles/components/Utility/Dropdown.module.scss";

const DropDownMenu = (props) => {
  const [open, setOpen] = useState(false);
  const container = useRef(null);

  const { arrow, dropType, cta, onChange, modifier } = props;

  block.setSettings({
    modifierDelimiter: "--",
  });

  const btn = block(button);
  const drop = block(dropdown);

  const handleItemClick = (e, item) => {
    e.preventDefault();
    setOpen(false);
    onChange(item);
  };

  const handleClickOutside = (event) => {
    if (container.current && !container.current.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  return (
    <div className={`${drop("")}` + ' ' + `${modifier && dropdown[`dropdown--${modifier}`]}`} ref={container}>
      <p
        className={
          `${btn("", { secondary: true, noTransform: true })}` +
          " " +
          `${drop("list")}` +
          " " +
          `${open && drop("list--open")}` +
          " " +
          `${arrow && drop("list--arrow")}`
        }
        onClick={() => setOpen(!open)}
      >
        {" "}
        <span className={drop("selected")}>{cta}{" "} </span>
        <span className={`${drop("arrow")}` + ' ' + `${open && drop("arrow--open")}`}><IconArrowRight /></span>
      </p>

      {open && (
        <div className={drop("wrapper")}>
          {dropType && <span className={drop("type")}>{dropType}</span>}
          <ul className={drop("menu")}>
            {props.list.map((item, key) => {
              return (
                <li className={drop("item")} key={key}>
                  <a
                    className={drop("link")}
                    href="#"
                    onClick={(e) => {
                      handleItemClick(e, item);
                    }}
                  >
                    {item}
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default DropDownMenu;
