import React from 'react';
import block from "bem-css-modules";
import switchbtn from "../../../styles/components//Utility/Switch.module.scss";

const Switch = function({isOn, handleToggle}) {
    block.setSettings({
        modifierDelimiter: "--",
    });

    const btn = block(switchbtn);
	
    let classSwitch = [`${switchbtn.switch}` + ' ' +  `${(isOn) ? btn('switch_is-on') : btn('switch_is-off')}`].join(" ");    
    let classToggle = [`${btn('toggle')}` + ' ' +  `${(isOn) ? btn('toggleRight') : btn('toggleLeft')}`].join(" ");
    
	return (
		<span className={classSwitch} onClick={handleToggle}>
            <span className={classToggle}></span>
		</span>
	);
}

export default Switch;