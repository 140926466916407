
import React from 'react';

export default function IconArrowRight()  {
    return (
      <svg width="5" height="10" viewBox="0 0 5 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.273241 0.171074C0.592985 -0.092245 1.06565 -0.0465032 1.32897 0.273241L4.82897 4.52324C5.05704 4.80018 5.05704 5.19986 4.82897 5.4768L1.32897 9.7268C1.06565 10.0465 0.592985 10.0923 0.273241 9.82897C-0.0465032 9.56565 -0.092245 9.09299 0.171074 8.77324L3.27843 5.00002L0.171074 1.2268C-0.092245 0.907058 -0.0465032 0.434392 0.273241 0.171074Z" fill="#555555"/>
      </svg>
    );
  }
