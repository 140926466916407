import React, { useState } from "react";
import Image from "next/image";
import block from "bem-css-modules";
import Loader from "../../components/Utility/Loader";
import Switch from "../../components/Utility/Switch";
import DropDownMenu from "../../components/Utility/DropDownMenu";
import button from "../../styles/components/Button.module.scss";
import pressRelease from "../../styles/components/Pressrelease.module.scss";
import useTranslation from "next-translate/useTranslation";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import slugify from 'react-slugify';

export default function PressRelease({
  spaceTop,
  spaceBottom,
  presReleases,
  post,
  onChangeYear,
  onChangeLanguage,
  onToggleRegulatory,
  onFetchMorePressReleases,
  pressReleaseDetail,
  morePressReleasesURL,
  morePressReleases,
  renderMoreReleases,
  loading,
  selectedLang
}) {
  const { t } = useTranslation();
  block.setSettings({
    modifierDelimiter: "--",
  });


  const langItems = [
    "English",
    "Swedish"
  ];

  const listItems = [
    "All years",
    "2021",
    "2020",
    "2019",
    "2018",
    "2017",
    "2016",
  ];

  const [isOn, setisOn] = useState(true);

  const handleToggle = () => {
    setisOn(!isOn);
    onToggleRegulatory(isOn);
  };

  const btn = block(button);
  const press = block(pressRelease);

  return (
    <div
      className={
        `${press("")}` +
        " " +
        `${spaceTop ? pressRelease["pressRelease--spaceTop"] : ""}` +
        " " +
        `${spaceBottom ? pressRelease["pressRelease--spaceBottom"] : ""}` +
        " " +
        `${!pressReleaseDetail ? pressRelease["pressRelease--bg"] : ""}`
      }
    >
      <div className={press("holder")}>
        {!pressReleaseDetail && (
          <h2 className={press("head")}>{post?.SecondTitle || ""}</h2>
        )}

        {pressReleaseDetail && (
          <section className={press("filterBlock")}>
            <div className={press("filterSwitch")}>
              <p className={press("filterText")}>
                {" "}
                Only Regulatory Press Releases
              </p>
              <div className={press("switch")}>
                <Switch isOn={isOn} handleToggle={handleToggle} />
              </div>
            </div>

            <DropDownMenu
              modifier={'mr10'}
              list={langItems}
              arrow
              dropType={"Choose language"}
              cta={selectedLang}
              onChange={onChangeLanguage}
            />

            <DropDownMenu
              list={listItems}
              arrow
              dropType={"Sort by"}
              cta={"Sort by year"}
              onChange={onChangeYear}
            />
          </section>
        )}

        {loading ? (
          <div className={press("loader")}>
            <Loader />
          </div>
        ) : (
          <section className={press("list")}>
            {!isEmpty(presReleases) &&
              !isEmpty(presReleases.Releases) &&
              presReleases.Releases.map((item, key) => (
                <a
                  className={press("link")}
                  // href={t("lang_route:investors.releases.article", {
                  //   slug: item.EncryptedId,
                  // })}
                  href={t("lang_route:investors.releases.article", {
                    slug: slugify(item.Title),
                  })}
                  key={key}
                >
                  <article className={press("item")}>
                    <div className={press("image")}>
                      {!isEmpty(item.Images) && (
                        <img
                          src={
                            item?.Images[0]?.UrlTo100x100Thumbnail ||
                            "/cdn/images/press.jpg"
                          }
                          alt={item.Title}
                          width={70}
                          height={70}
                          layout="fixed"
                          className={press("thumbnail")}
                        />
                      )}
                    </div>
                    <div className={press("detail")}>
                      <span className={press("date")}>
                        {moment(item.PublishDate).format("DD MMMM YYYY")}{" "}
                      </span>
                      <h3 className={press("title")}>{item.Title}</h3>
                    </div>
                  </article>
                </a>
              ))}
          </section>
        )}

        {renderMoreReleases && (
          <a
            className={
              `${btn("", { tertiary: true })}` +
              " " +
              `${morePressReleases && btn("", { loading: true })}`
            }
            href={morePressReleasesURL || "#"}
            onClick={(e) => {
              if (!morePressReleasesURL) {
                e.preventDefault();
                onFetchMorePressReleases();
              }
            }}
          >
            {post?.PressReleaseBtnText || ""}
          </a>
        )}
      </div>
    </div>
  );
}
